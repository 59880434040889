import React from 'react'
import { GrFacebookOption } from "react-icons/gr";
// import { FiYoutube } from "react-icons/fi";
// import { AiOutlineSkype } from "react-icons/ai";
import { FaInstagram } from 'react-icons/fa';
import { FaWhatsapp } from "react-icons/fa";
import { FaLinkedin } from 'react-icons/fa';
import Fade from "react-reveal/Fade";

function Footer() {
  return (
    <div className="bg-[#211D32]   lg:px-28 pt-14 px-10 pb-10">
      <Fade bottom cascade>
        <div className="grid grid-cols-1 lg:grid-cols-2 lg:gap-20">

          <div>

            {/* <h1 className="text-white font-semibold lg:text-2xl">IDAUD</h1> */}
            {/* <p className="text-[#7A7893] xl:w-[559px] pt-4">
             
            </p> */}
            <div className='mb-4'>
              <img src="https://i.ibb.co/4RyF0kp/footerimg.jpg" alt="idaud" className="w-auto h-36" />

            </div>
            <div className="grid lg:grid-cols-1 xl:grid-cols-2 grid-cols-1 md:grid-cols-1 pt-9">
              <div className="flex gap-2">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white bg-[#7A7893] rounded-full leading-4 p-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z"
                  />
                </svg>
                <div>
                  <p className="text-[#7A7893] text-sm">Have a question?</p>
                  <h3 className="text-white">+923020282642</h3>
                </div>
              </div>

              <div className="flex gap-2 text-white md:pt-4 pt-4 xl:pt-0">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  className="h-8 w-8 text-white bg-[#7A7893] rounded-full leading-4 p-2"
                  fill="none"
                  viewBox="0 0 24 24"
                  stroke="currentColor"
                  strokeWidth="2"
                >
                  <path
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    d="M20 13V6a2 2 0 00-2-2H6a2 2 0 00-2 2v7m16 0v5a2 2 0 01-2 2H6a2 2 0 01-2-2v-5m16 0h-2.586a1 1 0 00-.707.293l-2.414 2.414a1 1 0 01-.707.293h-3.172a1 1 0 01-.707-.293l-2.414-2.414A1 1 0 006.586 13H4"
                  />
                </svg>
                <div>
                  <p className="text-[#7A7893] text-sm">Contact us at</p>
                  <h3>idaudpublicschool@gmail.com</h3>
                </div>
              </div>
            </div>
            <h2 className="text-white font-semibold lg:text-xl md:pt-4 pt-4">
              Address
            </h2>
            <p className="text-[#7A7893] xl:w-[555px] pt-4 pb-5">
              R-56, Pioneer Fountain, Phase 2, Sector 16 - B, Scheme 33, Gulzar-e-Hijri, Karachi, Pakistan.

            </p>
          </div>




          <div>
            <div>
              <h2 className="text-white font-semibold lg:text-xl md:pt-4 pt-4">
                Newsletter
              </h2>
              <p className="text-[#7A7893] xl:w-[555px] pt-4 pb-5">
                Be the first one to know about admisions, offers and events.
                Unsubscribe whenever you like.
              </p>
              <div className="relative">
                <input
                  placeholder="Enter your email"
                  className="rounded-full px-4 py-3 bg-[#342F49] w-full input-p"
                ></input>
                <button className="text-white md:px-7 md:py-3 px-3 py-3 text-xs md:text-base rounded-full bg-[#6366F1] absolute right-0.5 submit-btn hover:text-white c-btn tracking-wider overflow-hidden">
                  <span className="absolute inset-0 bg-[#5558ff]"></span>
                  <span className="absolute inset-0 flex justify-center items-center">
                    Submit
                  </span>
                  Submit
                </button>
              </div>
            </div>

            <div className="flex justify-end pt-5 gap-4 socials">
              <div className="relative overflow-hidden block footer-div cursor-pointer">
                <span className="block">
                  <a href="https://www.facebook.com/profile.php?id=61555602721685&mibextid=2JQ9oc" target="_blank">
                    <GrFacebookOption className="text-white bg-[#7A7893] rounded-full leading-4 p-2 h-8 w-8" />
                  </a>
                </span>
                <span className="block">
                  <a href="https://www.facebook.com/profile.php?id=61555602721685&mibextid=2JQ9oc" target="_blank">
                    <GrFacebookOption className="text-white bg-[#6366F1] rounded-full leading-4 p-2 h-8 w-8" />
                  </a>
                </span>
              </div>

              <div className="relative overflow-hidden block footer-div cursor-pointer">
                <span className="block">
                  <FaInstagram className="text-white bg-[#7A7893] rounded-full leading-4 p-2 h-8 w-8" />
                </span>
                <span>
                  <a href="https://www.instagram.com/idaudpublicschool/?igsh=MXFkM3J3c3U1YW9ubA%3D%3D&utm" target="_blank">
                    <FaInstagram className="rounded-full leading-4 p-2 h-8 w-8 bg-gradient-to-br from-yellow-400 via-orange-400 to-pink-600 text-white" />
                  </a>
                </span>
              </div>

              <div className="relative overflow-hidden block footer-div cursor-pointer">
                <span className="block">
                  <FaLinkedin className="text-white bg-[#7A7893] rounded-full leading-4 p-2 h-8 w-8" />
                </span>
                <span>
                  <a href="https://www.linkedin.com/company/idaud-publicschool/" target="_blank">
                    <FaLinkedin className="text-white  bg-[#0077b5] rounded-full leading-4 p-2 h-8 w-8" />
                  </a>
                </span>
              </div>



              <div className="relative overflow-hidden block footer-div cursor-pointer">
                <span className="block">
                  <FaWhatsapp className="text-white bg-[#7A7893] rounded-full leading-4 p-2 h-8 w-8" />
                </span>
                <span>
                  <a href="http://Wa.me/+923020282642" target="_blank">
                    <FaWhatsapp className="text-white bg-[#307f63] rounded-full leading-4 p-2 h-8 w-8" />
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>

        {/* Lower footer */}
        <div className="grid lg:grid-cols-2 grid-cols-1 pt-16 md:grid-cols-1">
          <div className="md:flex justify-between text-[#7A7893] text-xs md:text-base flex-col lg:flex-row">
            {/* <p>About Us</p>
            <p>Contact</p>
            <p>Privacy Policy</p>
            <p>Sitemap</p>
            <p>Terms of Use</p> */}
          </div>
          <div className="text-[#7A7893] lg:justify-end justify-start pt-4 flex md:justify-start md:pt-4 text-xs md:text-base lg:pt-0">
            © {new Date().getFullYear()}, All Rights Reserved
          </div>
          <div className="text-[#7A7893] lg:pt-4 justify-center pt-4 flex md:justify-start md:pt-4 text-xs md:text-base lg:pt-0">
            Made with ❤️ by &nbsp; <br />
            <a href="https://nediantechnologies.com" target="_blank" className="text-white underline">nt</a>
          </div>


        </div>
      </Fade>
    </div>
  );
}

export default Footer