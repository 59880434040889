import Header from './Components/Header';
import About from './Components/About'
import Academics from './Components/Academics';
import './app.css'
// import Preloader from './Components/Preloader';
import Footer from './Components/Footer';
import ScrollArrow from './Components/ScrollArrow';
import Sitemap from './Components/Sitemap';
import Mission from './Components/Mission';
import Advertisement from './Components/Advertisement';


function App() {
  return (
    <div className="App font-link">
      {/* <Preloader /> */}
      {/* <Advertisement /> */}
        <Header id="home" />
        <ScrollArrow/>
        <About id="about" />
        <Academics id="academics" />
        <Mission />
        <Sitemap id="sitemap"/>
        <Footer />
    </div>
  );
}

export default App;
