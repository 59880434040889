import React from 'react'
import "react-responsive-carousel/lib/styles/carousel.min.css";
// import { Carousel } from "react-responsive-carousel";



function CarouselMission() {
  return (
    <div className="pt-10 pb-10 px-10 bg-[#231E3D]">
      {/* <Carousel> */}
        <div className="grid lg:grid-cols-2 gap-5">
          <img src="https://i.ibb.co/3TTfC7D/idaudschoolmain.jpg" alt='idaud' />
          <div className="text-left">
         
            <h1 className="text-white lg:text-3xl text-center md:text-2xl px-10 mt-10">
              We Built Futures
            </h1>
            <p className=" lg:px-5 pt-4 text-pretty	 text-[#ccc]">
            At IDAUD Public School, our mission is clear : To empower young minds and nurture souls through a holistic approach to education. We are committed to providing a nurturing and inclusive environment where every student can thrive academically, spiritually, and personally. With a focus on excellence, integrity, and compassion, we strive to instill in our students the values and skills they need to become responsible global citizens and leaders of tomorrow. Through innovative teaching methodologies, rigorous academic programs, and a strong emphasis on Islamic Tarbiah & Grooming, we aim to inspire a lifelong love for learning and a deep connection to their faith and community. Together, we are shaping the future, one student at a time.

            </p>
          </div>
        </div>
        
      {/* </Carousel> */}
    </div>
  );
}

export default CarouselMission