import React from "react";
import Fade from "react-reveal/Fade";

function Services() {
  return (
    <div className=" pt-14   lg:px-28 px-10 pb-10" id="academics">
      <Fade bottom cascade>
        <div className="w-10 h-1 from-[#7361F2] to-[#BD4FF1] bg-gradient-to-r"></div>
        <h1 className="text-gray-800 text-4xl font-semibold pt-3">ACADEMICS</h1>
        <div>
        <p className="text-[#696984] font-semibold pt-4 text-pretty	">
        Our academic programs at IDAUD Public School are designed to challenge and inspire students to reach their full potential. From interactive learning experiences to personalized attention, we strive to create a dynamic classroom environment where every child can excel. With a strong emphasis on core subjects and critical thinking skills, we equip our students with the tools they need to succeed academically and beyond.        </p>

        </div>

        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 pt-10 lg:pb-20">
          <div className="p-8 bg-[#211D32] flex text-white justify-between font-bold cursor-pointer relative group">
          Curriculum:
We offer a diverse and dynamic curriculum that encompasses a wide range of subjects and disciplines. From core subjects such as Mathematics, Science, English, and Social Studies to specialized courses in Islamic Studies, Arabic, and Computer Science, our curriculum is designed to challenge and engage students at every grade level.
         
          </div>
          <div className="p-8 bg-[#211D32] flex text-white justify-between cursor-pointer font-bold">
          Teaching Methodologies:
Our dedicated faculty members employ a variety of teaching methodologies to cater to the diverse learning needs and styles of our students. From interactive lectures and hands-on activities to project-based learning and collaborative group work, we strive to create a stimulating and inclusive learning environment where every student can thrive.
           
          </div>
          <div className="p-8 bg-[#211D32] flex text-white justify-between cursor-pointer font-bold">
          Extracurricular Activities:
In addition to our academic offerings, we provide a rich array of extracurricular activities that complement and enhance the learning experience. Whether it's participating in sports teams, joining clubs and organizations, or engaging in community service projects, students have ample opportunities to explore their interests and talents beyond the classroom.
           
          </div>
          <div className="p-8 bg-[#211D32] flex text-white justify-between cursor-pointer font-bold">
          Assessment and Grading:
We believe in fair and transparent assessment practices that accurately measure student progress and achievement. Our assessment methods include a combination of quizzes, exams, projects, presentations, and class participation, allowing students to demonstrate their knowledge and skills in diverse ways.

          </div>
          <div className="p-8 bg-[#211D32] flex text-white justify-between cursor-pointer font-bold">
          Support Services:
We are committed to supporting the academic success and well-being of every student. Our dedicated team of educators, counselors, and support staff provide a range of support services, including tutoring, academic advising, and counseling, to ensure that all students have the resources they need to thrive academically and personally.
          
          </div>
          <div className="p-8 bg-[#211D32] flex text-white justify-between cursor-pointer font-bold">
          Academic Policies:
We uphold high standards of academic integrity and professionalism at IDAUD Public School. Our academic policies are designed to promote honesty, integrity, and ethical behavior among students and staff. From attendance requirements to grading policies, we strive to create a fair and transparent learning environment where all students can excel.
       
          </div>
        
        </div>
      </Fade>
    </div>
  );
}

export default Services;
