import React from 'react';
import Fade from "react-reveal/Fade";

function About() {
  return (
    <div className="from-[#1E1732] to-[#211935] bg-gradient-to-r pt-14   lg:px-28 px-10 pb-10" id="about">
      <Fade bottom cascade>
        <div className="w-10 h-1 from-[#7361F2] to-[#BD4FF1] bg-gradient-to-r"></div>
        <h1 className="text-white text-4xl font-semibold pt-3">ABOUT US</h1>
        <div>

          <p className="text-white font-semibold pt-4 ">
          Welcome to IDAUD Public School, a beacon of educational excellence and Islamic values in the heart of Karachi. Established with a vision to provide quality education infused with Islamic Tarbiah, our school is dedicated to nurturing the minds and hearts of young learners. With a rich blend of academic rigor, moral guidance, and community engagement, we strive to create an environment where students can flourish academically, spiritually, and socially.
          </p>
          <p className="text-white font-semibold pt-4 ">
          We believe in fostering a love for learning that goes beyond the confines of the classroom. Our dedicated faculty members are committed to providing personalized attention and support to each student, ensuring their holistic development and success. Through innovative teaching methodologies, extracurricular activities, and community outreach initiatives, we aim to empower students to become lifelong learners, compassionate leaders, and positive contributors to society.
          </p>

        </div>
      </Fade>
    </div>
  );
}

export default About