import React from "react";
import CarouselClients from "./CarouselMission";
import Fade from "react-reveal/Fade";

function Mission() {
  return (
    <div>
      <div className="bg-[#1a3560] pt-10   lg:px-28 px-10 pb-10">
        <Fade bottom cascade>
         
          <h1 className="lg:text-3xl text-white text-center ml-10 text-3xl font-semibold whitespace-nowrap">
Our Mission          </h1>
<div className="mt-10">

</div>

          <CarouselClients />
        </Fade>
      </div>
    </div>
  );
}

export default Mission;
