import React from 'react';
import Fade from "react-reveal/Fade";

function Sitemap() {
  return (
    <div className="pt-5 lg:px-28 px-10 pb-10" id='sitemap'>
      <Fade bottom cascade>
      <h1 className="lg:text-3xl text-zinc-700 text-center text-3xl font-semibold whitespace-nowrap">
          Sitemap</h1>

      {/* Google Map */}
      <div className="w-full max-w-full relative mt-5">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3617.5293393998736!2d67.10593907393847!3d24.948098841747825!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3eb339005d4bf0f9%3A0x2542adf63241629c!2sIDAUD%20Public%20School!5e0!3m2!1sen!2s!4v1707482611793!5m2!1sen!2s"
        width="100%"
        height="400"
        style={{ border: 0 }}
        allowfullscreen=""
        loading="lazy"
        referrerpolicy="no-referrer-when-downgrade"
      />
    </div>
          </Fade>
    </div>
  );
}

export default Sitemap;
