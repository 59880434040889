import React from "react";
import Navbar from "./Navbar";
import Fade from "react-reveal/Fade";
import bgImg from '../assets/2.jpg'
function Header() {
  return (
    <div className=" w-full px-10 lg:px-28 bg bg-no-repeat bg-cover sm:h-screen overflow-hidden" style={{ backgroundImage: `url(${bgImg})` }}>
      <Navbar className="z-50" />
      <div className="lg:flex lg:pt-8">
        <Fade left cascade>
          <div className="  drop-shadow-2xl lg:w-[800px] container  xl:pt-24 lg:pt-10 pt-20">
            <h1 className="font-[1000] xl:text-[4.2rem] lg:text-[3rem] md:text-[3.2rem] text-3xl lg:w-[79%] text-white xl:leading-[5rem] md:leading-[4rem]">
              Quality Education with{" "}
              <span className="">
                {" "}
                Islamic{" "}
              </span>
              Tarbiah
            </h1>
            <div className="xl:flex justify-start mt-7">


              <div className="absolute inset-y-0 right-0 flex items-center pr-2 sm:static sm:inset-auto sm:ml-6 sm:pr-0 contact-btn">
                <a href="https://wa.me/+923020282642" >

                  <button className="text-white border rounded px-7 py-2 md:px-5 hover:text-white c-btn relative tracking-wider overflow-hidden">
                    <span className="absolute inset-0 bg-gradient-to-br from-[#403f6d] to-[#4334b6]"></span>
                    <span className="absolute inset-0 flex justify-center items-center">
                      Register Now
                    </span>
                    Register Now
                  </button>
                </a>
              </div>


            </div>
          </div>
        </Fade>

        <Fade right cascade>

        </Fade>
      </div>
    </div>
  );
}

export default Header;
